import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs, { ActiveBreadcrump } from "../../../components/header/Breadcrumbs";
import { outletContext } from "./Claim";
import Loader from "../../../components/Loader/Loader";
import { useEffect, useState } from "react";
import EntityListing from "../../../components/EntityListing/EntityListing";
import Tag from "../../../components/UI/Tags/Tag";
import { errorHandler, toTitleCase } from "../../../utils/helper";
import api from "../../../api/api";
import axios from "axios";
import { useSelector } from "react-redux";
import { EDIT_PERMISSION } from "../../../constants/types";
import { successNotification } from "../../../components/UI/Toast/Toast";
import EntityDetail from "../../../components/EntityDetail/EntityDetail";

const Communication: React.FC = () => {
  const context = useOutletContext<outletContext>(),
    source = axios.CancelToken.source(),
    { claimid } = useParams(),
    [params, setParams] = useState({}),
    [searchParams, setSearchParams] = useSearchParams(),
    [checkList, setCheckList] = useState([]),
    [btnLoading, setBtnLoading] = useState(false),
    [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'EMAIL'),
    editPermission = useSelector((state: any) => state.auth.permissions)?.claims?.includes(EDIT_PERMISSION),
    getCheckList = (item: any) => {
      setCheckList(item);
    },
    columns = [
      {
        title: 'Communication Details',
        dataIndex: 'name',
        key: 'name',
        render: (value: any) => {
          return value || '-';
        },
      },
      {
        title: 'Last Activity',
        dataIndex: ['communication_details','last_activity'],
        key: ['communication_details','last_activity'],
      },
      {
        title: 'Status',
        dataIndex: ['communication_details','sent'],
        key: ['communication_details','sent'],
        render: (value: any) => {
          if (value) {
            return <Tag type="sent">Sent</Tag>
          }
          return <Tag type="unsent">Not Sent</Tag>
        }
      },
    ],
    tabChangeHandler = (tab: string) => {
      setActiveTab(tab);
      setSearchParams({ tab: tab }, { replace: true });
      setParams((prev: any) => ({ ...prev }));
    },
    sendCommunication = () => {
      setBtnLoading(true);
      api.claims_management.sendCommunication({
        claim_uuid: claimid,
        communication_uuids: checkList,
      })
        .then((res: any) => {
          successNotification(res.message);
          setBtnLoading(false);
          setParams((prev: any) => ({ ...prev }));
        })
        .catch((err: any) => {
          errorHandler(err?.response?.data);
          setBtnLoading(false);
        })
    },
    tabs = {
      activeTab: activeTab,
      tabChangeHandler: tabChangeHandler,
      list: [
        {
          key: 'EMAIL',
          text: 'Email',
          notification: false,
        },
        {
          key: 'WHATSAPP',
          text: 'Whatsapp',
          notification: false,
        },
      ],
    },
    cta = {
      button: [
        {
          text: `Send communication`,
          editPermission: editPermission,
          loading: btnLoading,
          disabled: checkList.length === 0,
          onClick: () => {
            sendCommunication();
          }
        }
      ],
    };

  return (
    <>
      <Breadcrumbs routes={context?.routes} />
      <EntityDetail keysToDisplay={[
        [{ key: 'user.email', value: 'Work Email' }, { key: 'user.personal_email', value: 'Personal Email' }],
        [{ key: 'user.phone', value: 'Phone number' }, { key: 'user.personal_phone', value: 'Personal Phone' }]
      ]} data={context.claim} route={[]} />
      <EntityListing
        hideBreadcrumb={true}
        checkList={checkList}
        getCheckList={getCheckList}
        params={params}
        hasPaging={false}
        header={columns}
        key={'uuid'}
        setParams={setParams}
        tableDataApi={() =>
          api.claims_management.fetchCommunication({
            claim_uuid: claimid,
            service: activeTab,
          }, source.token)
        }
        type="communication"
        tabs={tabs}
        cta={cta}
      />
    </>
  )
}

export default Communication;
