import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  primaryColor: '',
  logoUrl: '',
  brandName: '',
  legalName: '',
  description: '',
  choices: [],
  settingsConfig: {},
  brokerUUID:'',
};

const brokerconfig = createSlice({
  name: 'brokerconfig',
  initialState,
  reducers: {
    setBrokerConfig: (state, action) => {
      state.primaryColor = action.payload.primaryColor;
      state.logoUrl = action.payload.logoUrl;
      state.brandName = action.payload.brandName;
      state.settingsConfig = action.payload.settingsConfig;
      state.brokerUUID = action?.payload?.brokerUUID;
    },
    setChoices: (state, action) => {
      state.choices = action.payload.choices;
    },
  },
});

export const brokerConfigActions = brokerconfig.actions;

export default brokerconfig.reducer;
