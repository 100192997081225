import { Col, Drawer, Form, Row, Select } from "antd";
import { toTitleCase } from "../../../utils/helper";
import { Button } from "../../../components/UI/Button/Button";
import { useEffect, useState } from "react";
import api from "../../../api/api";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";

interface Props {
  claim: any;
  onClose: any;
  visible: any;
  updateClaim: any;
}

const TagMemberDrawer: React.FC<Props> = ({ claim, onClose, visible, updateClaim }) => {
  const [form] = useForm(),
  { id, claimid } = useParams(),
    [memberSelectLoading, setMemberSelectLoading] = useState<boolean>(false),
    [listOfMembers, setListOfMembers] = useState<Array<any> | undefined>(undefined),
    [selectedPrimaryMember, setSelectedPrimaryMember] = useState<any>(),
    [memberName, setMemberName] = useState<any | undefined>(undefined),
    fetchMembers = () => {
      setMemberSelectLoading(true);
      api.claims_management
        .populateClaims({
          organisation_uuid: id || claim?.organisation?.organisation_uuid,
          policy_uuid: claim?.policy_uuid,
          ...(memberName && {
            member_name: memberName,
          }),
        })
        .then((response) => {
          setListOfMembers(response?.data?.member_list);
          setMemberSelectLoading(false);
        })
        .catch((error) => {
          setMemberSelectLoading(false);
        });
    },
    updateDetails = () => {
      form.validateFields()
        .then((values) => {
          updateClaim(claimid, {
            member_id: values?.primary_member,
            raised_for_id: values?.raised_for_id
          }, 'Member successfully mapped to this claim')
        });
    };

  useEffect(() => {
    if (visible && !listOfMembers) {
      fetchMembers()
    }
  }, [visible, listOfMembers])

  useEffect(() => {
    let timer = setTimeout(() => {
      if (memberName) {
        fetchMembers();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [memberName]);

  return <Drawer visible={visible} onClose={onClose} destroyOnClose title="Tag this claim to a member" closable={false} closeIcon={null}>
    <Form form={form}>
      <Form.Item
        label="Employee Name"
        labelCol={{ span: 24 }}
        name="primary_member"
        validateTrigger="onBlur"
        rules={[{ required: true, message: 'Please select a member!' }]}
      >
        <Select
          showSearch
          notFoundContent={'Search by employee name'}
          loading={memberSelectLoading}
          filterOption={(inputValue: any, option: any) =>
            option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
          }
          onSearch={(value: any) => {
            setMemberName(value);
            if (value === '') {
              setListOfMembers(undefined);
            }
          }}
          onSelect={(value: any) => {
            setSelectedPrimaryMember(value);
          }}
        >
          {listOfMembers && listOfMembers?.length > 0 &&
            listOfMembers
              ?.filter((item: any) => item?.relation === 'SELF')
              ?.map((data: any) => {
                return (
                  <Select.Option key={data?.id} value={data?.id}>
                    {`${toTitleCase(data?.full_name)} (${data?.employee_id}) - ${toTitleCase(data?.status)}`}
                  </Select.Option>
                );
              })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Raised For"
        labelCol={{ span: 24 }}
        name="raised_for_id"
        validateTrigger="onBlur"
        rules={[{ required: true, message: 'Please select a member!' }]}
      >
        <Select
          showSearch
          notFoundContent={'No members found for the selected member'}
          loading={memberSelectLoading}
          filterOption={(inputValue: any, option: any) =>
            option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
          }
        >
          {listOfMembers && listOfMembers?.length > 0 &&
            listOfMembers
              ?.filter(
                (item: any) =>
                  item?.employee_id ===
                  listOfMembers?.find((item: any) => item?.id === selectedPrimaryMember)?.employee_id
              )
              ?.map((data: any) => {
                return (
                  <Select.Option key={data?.id} value={data?.id}>
                    {`${toTitleCase(data?.full_name)} (${toTitleCase(data?.relation)})`}
                  </Select.Option>
                );
              })}
        </Select>
      </Form.Item>
    </Form>
    <Row gutter={16} className="drawer-footer mt-36">
      <Col>
        <Button type="secondary" onClick={onClose}>Cancel</Button>
      </Col>
      <Col>
        <Button type="primary" onClick={updateDetails}>Save</Button>
      </Col>
    </Row>
  </Drawer >
};

export default TagMemberDrawer;
