let API_URL = process.env.REACT_APP_API_URL || (window as any).env.API_URL;
let COUNTRY = 'IN';
let COUNTRY_CODES = { IN: '+91' } as any;
export let DEFAULT_PAGE_SIZE = '10';
export const COUNTRY_CODE = COUNTRY_CODES[COUNTRY];
// let API_URL = (window as any).env.API_URL;

if (!API_URL) {
  if (window.location.hostname === 'localhost') {
    API_URL = '';
  } else {
    API_URL = window.location.protocol + '//' + window.location.hostname.replace('portal', 'api') + '/';
  }
}
export const FAMILY_MEMBER_PRIORITY = [
  'SELF',
  'SPOUSE',
  'CHILD',
  'DAUGHTER',
  'SON',
  'PARENT',
  'PARENTS',
  'MOTHER',
  'FATHER',
  'PARENT_IN_LAW',
  'PARENTS_IN_LAW',
  'MOTHER_IN_LAW',
  'FATHER_IN_LAW',
];
export { API_URL };
export const GOOGLE_ANALYTICS_TRACKING_CODE = 'G-VZ48PXJQ3M';
export const PRIMARY_DOMAIN = 'bharatsure.com';
export const DEFAULT_COLOR = '#11A387';
export const UNDEFINED_ARRAY = [null, undefined, false]