import { List, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../../api/api';
import Loader from '../../../components/Loader/Loader';
import TableComponent from '../../../components/UI/Table/TableComponent';
import { errorHandler, toKeyCase, toTitleCase } from '../../../utils/helper';



const PrivacyAndSupport: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true),
        [supportType, setSupportType] = useState<string>(''),
        checkSupportType = () => {
            setSupportType('tech');
        }, [dataSource, setDataSource] = useState<Array<any> | null>(),
        updateNotification = (id: number, enabled: boolean) => {
            api.settings.updateNotificationSettings(id, { enabled: enabled })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    errorHandler(error?.response)
                });
        },
        getTableData = () => {
            api.settings.getNotificationSettings()
                .then((response) => {
                    setDataSource(response?.data);
                    setLoading(false);
                })
                .catch((error) => {
                    errorHandler(error?.response);
                    setLoading(false);
                });
        },
        toggle = (channel: any) => {
            return (
                <Switch
                    defaultChecked={channel?.enabled}
                    onChange={(val) => {
                        updateNotification(channel?.id, val);
                    }}
                />
            )
        },
        columns = [
            {
                title: 'Support Type',
                dataIndex: 'support_type',
                render: (value: any) => toTitleCase(value),
            },
            {
                title: `Status`,
                dataIndex: 'status',
                render: (value: any) => toggle(toKeyCase(value)),
            },
        ],
        permissions = useSelector((state: any) => state.auth.permissions),
        listDataSource = [
            'Dashboard',
            'Clients',
            'Claims',
            'Products',
            'User management',
        ];

    useEffect(() => {
        checkSupportType();
        getTableData();
    }, []);


    return (
        <Loader
            loading={loading}
        >
            <div className="mt-56">
                <div className="title">
                    Privacy
                </div>
                <div className="mt-24"></div>
                <div className="title mt-56">Support</div>
                {supportType === 'tech' && <div className="mt-24">
                    In case of any queries or support, please contact us at {`care@bharatsure.com`}
                </div>}
                {supportType !== 'tech' && <div className="mt-24">
                    In order to provide operations and claims support to your clients, {`Bharatsure`} will require access to your business data. This data will be only used for the purpose of providing support to your clients. The data can be accessed only by allowing access using the option below and this action is reversible. By giving permission, you are allowing access to the following:
                    <div className='mt-16'>
                        <List
                            size="large"
                            bordered
                            dataSource={listDataSource}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                    </div>

                    In case of any queries or support, please contact us at {`care@bharatsure.com`}

                    <div className="mt-32">
                        <TableComponent
                            columns={columns}
                            dataSource={dataSource || []}
                            loading={loading}
                            hasPaging={false}
                        />
                    </div>
                </div>}
            </div>
        </Loader>
    );
};

export default PrivacyAndSupport;
