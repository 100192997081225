import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Empty, Popconfirm, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import api from "../../../api/api";
import Loader from "../../../components/Loader/Loader";
import TableCTAComponent from "../../../components/UI/Table/TableCTAComponent";
import TableComponent from "../../../components/UI/Table/TableComponent";
import Tag from "../../../components/UI/Tags/Tag";
import { successNotification } from "../../../components/UI/Toast/Toast";
import Breadcrumbs from "../../../components/header/Breadcrumbs";
import { EDIT_PERMISSION } from "../../../constants/types";
import { errorHandler, toTitleCase } from "../../../utils/helper";
import { outletContext } from "./Claim";
import EditDocument from "./EditDocument";
import { createBrowserHistory } from 'history';


const Documents: React.FC = () => {
  const [loading, setLoading] = useState(false),
    [doNotAskAgain, setDoNotAskAgain] = useState(true),
    [isModalVisible, setIsModalVisible] = useState(false),
    history = createBrowserHistory({}),
    [documents, setDocuments] = useState<Array<any> | null>(null),
    [visible, setVisible] = useState(false),
    navigate = useNavigate(),
    tabOptions = useSelector((state: any) => state.masterdata.choices)?.insurer_type_choices,
    [curRecord, setCurRecord] = useState<any>(null),
    [activeType, setActiveType] = useState<any>(null),
    context = useOutletContext<outletContext>(),
    [activeTab, setActiveTab] = useState<string>(tabOptions[0]),
    { claimid } = useParams(),
    editPermission = useSelector((state: any) => state.auth.permissions)?.claims?.includes(EDIT_PERMISSION),
    downloadAllDocuments = () => {
      api.claims_management.downloadDocuments({
        ...claimid && { claim_uuid: claimid }
      })
        .then((res: any) => {
          history.push(res?.data)
        })
        .catch((err: any) => { errorHandler(err?.response?.data) })
    },
    getDocuments = () => {
      api.claims_management.getDocuments({ claim_uuid: claimid, product_uuid: context?.claim?.product?.uuid, insurer_type: activeTab })
        .then((res) => {
          setDocuments(res?.data);
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        })
    },
    uploadDocument = (id: any) => {
      setActiveType(id);
      setCurRecord({
        "documents": [{
          document_link: null,
          message: null,
        }]
      })
      setVisible(true);
    },
    editDocument = (record: any) => {
      setCurRecord(record);
      setVisible(true);
    },
    deleteDocument = (record: any) => {
      api.claims_management.editDocument(record?.uuid, {
        message: record?.message,
        document_link: record?.document_link,
        is_active: false,
      })
        .then((res) => {
          successNotification(res?.message);
          getDocuments();
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        })
    },
    expandedColumns = (record: any) => {
      const col = [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   key: 'id',
        // },
        {
          title: 'Uploaded Document',
          dataIndex: 'document_link',
          key: 'document_link',
          render: (value: any) => (
            <a target="_blank" href={value} className="cta">Click to download</a>
          )
        },
        {
          title: 'Modified at',
          dataIndex: 'modified_at',
          key: 'modified_at',
          render: (value: any) => (
            moment(value).format('DD/MM/YYYY')
          )
        },
        {
          title: 'Created At',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (value: any) => (
            moment(value).format('DD/MM/YYYY')
          )
        },
        {
          title: 'Remarks',
          dataIndex: 'message',
          key: 'message',
          render: (value: any) => (
            value ? value : '-'
          )
        },
        {
          title: 'Status',
          dataIndex: 'verified',
          key: 'verified',
          render: (value: any) => (
            value ? <Tag type='success'>Verified</Tag> : <Tag type='pending'>Unverified</Tag>
          )
        },
        {
          title: '',
          dataIndex: 'actions',
          key: 'actions',
          fixed: 'right',
          align: 'right',
          render: (text: any, record: any) => (
            <Row align="middle" gutter={16}>
              <Col>
                <EditOutlined className="cta" onClick={() => editDocument(record)} />
              </Col>
              <Col>
                <Popconfirm
                  title="Are you sure you want to delete this document?"
                  okText="Confirm"
                  onConfirm={() => {
                    deleteDocument(record)
                  }}
                >
                  <DeleteOutlined className="cta" />
                </Popconfirm>
              </Col>
            </Row>
          )
        },
      ]

      return <TableComponent hasPaging={false} columns={col} dataSource={record?.documents} />
    },
    onClose = () => {
      setVisible(false);
      setCurRecord(null);
      setActiveType(null);
      if (!doNotAskAgain) {
        setIsModalVisible(true)
      }
    },
    columns = [
      {
        title: 'Document Type',
        dataIndex: 'heading',
        key: 'heading',
        width: '40%',
        render: (text: any, record: any) => <span className={`${record?.mandatory ? "required" : ''}`}>{toTitleCase(record?.heading)}</span>
      },
      {
        title: 'No. of documents',
        dataIndex: 'no_of_uploaded_document',
        key: 'no_of_uploaded_document',
        width: '40%',
        render: (text: any, record: any) => {
          return (
            `${record?.documents?.length} Documents Uploaded`
          )
        }
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',
        align: 'right',
        width: '20%',
        render: (text: any, record: any) => (
          <span className="cta" onClick={() => { uploadDocument(record?.uuid) }}>Click to upload</span>
        )
      },
      Table.EXPAND_COLUMN,
    ],
    tabChangeHandler = (key: any) => { setActiveTab(key) },
    tabs = tabOptions?.map((tab: any) => {
      return {
        key: `${tab}`,
        text: `From ${tab}`,
        notification: false,
        ...tab?.toLowerCase() === 'insurer' && { tooltip: "These are the documents received from the insurer for the insured person’s notice." },
        ...tab?.toLowerCase() === 'insured' && { tooltip: "These are the documents required from the insured for the insurer to process the claim" }
      }
    });

  useEffect(() => {
    setLoading(true);
    getDocuments();
  }, [activeTab])

  useEffect(() => {
    if (documents) {
      setLoading(false);
    }
  }, [documents])

  return <>
    {visible && <EditDocument getDocuments={getDocuments} onClose={onClose} data={curRecord} id={activeType} visible={visible} />}
    <Breadcrumbs routes={context?.routes} />
    <TableCTAComponent cta={
      {
        button: [...tabOptions[0] === activeTab ? [{
          text: "Download All",
          loading: loading,
          type: 'primary',
          onClick: () => downloadAllDocuments()
        }] : []]
      }
    } tabs={{
      list: tabs,
      activeTab,
      tabChangeHandler,
    }} />
    <Loader
      loading={loading}
      screen='claims'
    >
      {documents && documents?.map((document: any, index: any) => (
        <React.Fragment key={index}>
          <div className="title">
            {document.heading}
          </div>
          <TableComponent
            columns={columns}
            dataSource={document?.type}
            hasPaging={false}
            expandable={expandedColumns}
            expandableKey="documents"
          />
        </React.Fragment>
      ))}

      {documents?.length === 0 && <Empty style={{ paddingTop: "60px" }} description={'No documents found'} />}
    </Loader>
  </>
}

export default Documents;
