import { Alert, Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import Breadcrumbs from "../../../components/header/Breadcrumbs";
import { Button } from "../../../components/UI/Button/Button";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { outletContext } from "./Claim";
import moment from "moment";
import { useEffect, useState } from "react";
import api from "../../../api/api";
import Loader from "../../../components/Loader/Loader";
import { successNotification } from "../../../components/UI/Toast/Toast";
import Tooltip from "../../../components/UI/Tooltip/Tooltip";
import { amountToInt, errorHandler, toTitleCase } from "../../../utils/helper";

const MemberClaimDetails: React.FC = () => {
  const context = useOutletContext<outletContext>(),
    [claimsManagers, setClaimsManagers] = useState<Array<any> | null>(null),
    { claimid } = useParams(),
    [form] = Form.useForm(),
    navigate = useNavigate(),
    fetchClaimsManagers = () => {
      api.clientaddition
        .userList('claim_manager')
        .then((response) => {
          setClaimsManagers(response.data);
        })
        .catch((error) => {
          errorHandler(error?.response?.data);
        });
    },
    initialValues = {
      ...context?.claim,
      deduction_amount: amountToInt(context?.claim?.claimed_amount) - amountToInt(context?.claim?.settled_amount),
      payable: amountToInt(context?.claim?.claimed_amount) - amountToInt(context?.claim?.settled_amount) - amountToInt(context?.claim?.hospital_discount),
      claims_manager_uuid: context?.claim?.claims_manager?.uuid,
      date_of_document_submission: context?.claim?.date_of_document_submission ? moment(context?.claim?.date_of_document_submission) : undefined,
      intimation_date: context?.claim?.intimation_date ? moment(context?.claim?.intimation_date) : undefined,
      date_of_query_raised: context?.claim?.date_of_query_raised ? moment(context?.claim?.date_of_query_raised) : undefined,
      date_of_query_doc_submission: context?.claim?.date_of_query_doc_submission ? moment(context?.claim?.date_of_query_doc_submission) : undefined,
      date_of_final_status: context?.claim?.date_of_final_status ? moment(context?.claim?.date_of_final_status) : undefined,
      date_of_settlement: context?.claim?.date_of_settlement ? moment(context?.claim?.date_of_settlement) : undefined,
      date_of_document_received: context?.claim?.date_of_document_received ? moment(context?.claim?.date_of_document_received) : undefined,
    },
    reformatPayload = (values: any) => {
      return {
        ...values,
        ...values?.claimed_amount && { claimed_amount: amountToInt(values?.claimed_amount) },
        ...values?.settled_amount && { settled_amount: amountToInt(values?.settled_amount) },
        ...values?.deduction_amount && { deduction_amount: amountToInt(values?.deduction_amount) },
        ...values?.hospital_discount && { hospital_discount: amountToInt(values?.hospital_discount) },
        ...values?.payable && { payable: amountToInt(values?.payable) },
        ...values?.date_of_document_submission && { date_of_document_submission: moment(values?.date_of_document_submission)?.format('YYYY-MM-DD') },
        ...values?.intimation_date && { intimation_date: moment(values?.intimation_date)?.format('YYYY-MM-DD HH:mm:ss') },
        ...values?.date_of_query_raised && { date_of_query_raised: moment(values?.date_of_query_raised)?.format('YYYY-MM-DD') },
        ...values?.date_of_query_doc_submission && { date_of_query_doc_submission: moment(values?.date_of_query_doc_submission)?.format('YYYY-MM-DD') },
        ...values?.date_of_final_status && { date_of_final_status: moment(values?.date_of_final_status)?.format('YYYY-MM-DD') },
        ...values?.date_of_settlement && { date_of_settlement: moment(values?.date_of_settlement)?.format('YYYY-MM-DD') },
        ...values?.date_of_document_received && { date_of_document_received: moment(values?.date_of_document_received)?.format('YYYY-MM-DD') },
      }
    },
    updateDetails = (redirect: boolean) => {
      form.validateFields()
        .then((values) => {
          context.updateClaim(claimid, reformatPayload(values), 'Claim details successfully updated')
          if (redirect) {
            navigate('../treatment-details')
          }
        });
    },
    updateDeductionAmount = () => {
      form.setFieldsValue({
        deduction_amount: amountToInt(form.getFieldValue('claimed_amount')) - amountToInt(form.getFieldValue('settled_amount'))
      });
    },
    updatePayableAmount = () => {
      form.setFieldsValue({
        payable: amountToInt(form.getFieldValue('deduction_amount')) - amountToInt(form.getFieldValue('hospital_discount')),
      });
    };

  useEffect(() => {
    fetchClaimsManagers();
    return () => {
      context.setErrorMessage(null)
    }
  }, [])

  useEffect(() => {
    updateDeductionAmount();
  }, [form.getFieldValue('claimed_amount'), form.getFieldValue('settled_amount')]);

  useEffect(() => {
    updatePayableAmount();
  }, [form.getFieldValue('deduction_amount'), form.getFieldValue('hospital_discount')]);

  return <Loader loading={!claimsManagers}>
    <Breadcrumbs routes={context?.routes} />
    <Form initialValues={initialValues} form={form} className="form-bottom-border">
      <div className="mt-56" >
        {context?.claim?.sync_status && context?.claim?.tpa?.additional_details?.api_available && <Alert type="warning" showIcon message="Fields marked with # are not fetched by API and have to be manually updated" />}
      </div>
      <Row gutter={138}>
        <Col span={8}>
          <div className="title mt-24">A. Claim Details</div>
          <Form.Item
            className={context?.isUnmappedField('type')}
            label={"Claim Type"}
            name="type"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Please select claim type" }]}
          >
            <Select
              onChange={() => context.changeHandler(['type'])}
              placeholder="Select"
              showSearch
            >
              {context.choices?.claim_type_choices?.map((data: any) => {
                return (
                  <Select.Option key={data} value={data}>
                    {toTitleCase(data)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {context.errorMessage?.type && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.type[0]}
            </div>
          )}
          <Form.Item
            label="Claim API Status"
            name="api_claim_status"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            className={context?.isUnmappedField('status')}
            label="Claim Status"
            name="status"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Please select claim status" }]}
          >
            <Select
              onChange={() => context.changeHandler(['status'])}
              placeholder="Select"
              showSearch
            >
              {context.choices?.claim_status_choices?.map((data: any, index: number) => {
                return (
                  <Select.Option key={index} value={data}>
                    {toTitleCase(data)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {context.errorMessage?.status && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.status[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('claim_number')}
            label={<>
              <span>Claims Number <Tooltip message="This is the claim number received from the insurer to track this claim" /></span>
            </>}
            name={"claim_number"}
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <Input placeholder="Enter Claim Number" />
          </Form.Item>
          {context.errorMessage?.claim_number && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.claim_number[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('claimed_amount')}
            label="Claim Amount"
            name="claimed_amount"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <InputNumber
              placeholder="Enter amount"
              onChange={() => context.changeHandler(['claimed_amount'])}
              min={0}
              size="small"
              addonBefore="₹"
              formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
              className="w-addon"
            />
          </Form.Item>
          {context.errorMessage?.claimed_amount && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.claimed_amount[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('settled_amount')}
            label="Settled Amount"
            name="settled_amount"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <InputNumber
              placeholder="Enter amount"
              min={0}
              size="small"
              onChange={() => context.changeHandler(['settled_amount'])}
              addonBefore="₹"
              formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
              className="w-addon"
            />
          </Form.Item>
          {context.errorMessage?.settled_amount && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.settled_amount[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('deduction_amount')}
            label="Deduction Amount"
            name="deduction_amount"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <InputNumber
              placeholder="Enter amount"
              min={0}
              onChange={() => context.changeHandler(['deduction_amount'])}
              disabled
              size="small"
              addonBefore="₹"
              formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
              className="w-addon"
            />
          </Form.Item>
          {context.errorMessage?.deduction_amount && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.deduction_amount[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('hospital_discount')}
            label="Hospital Discount"
            name="hospital_discount"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <InputNumber
              placeholder="Enter amount"
              min={0}
              size="small"
              onChange={() => context.changeHandler(['hospital_discount'])}
              addonBefore="₹"
              formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
              className="w-addon"
            />
          </Form.Item>
          {context.errorMessage?.hospital_discount && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.hospital_discount[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('payable')}
            label="Payable by the insured"
            name="payable"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <InputNumber
              placeholder="Enter amount"
              min={0}
              size="small"
              onChange={() => context.changeHandler(['payable'])}
              disabled
              addonBefore="₹"
              formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
              className="w-addon"
            />
          </Form.Item>
          {context.errorMessage?.payable && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.payable[0]}
            </div>
          )}

        </Col>
        <Col span={8}>
          <div className="title mt-24">B. Process Details</div>
          <Form.Item
            className={context?.isUnmappedField('claims_manager_uuid', ["claims_manager", "uuid"])}
            label="Claims Manager"
            name="claims_manager_uuid"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Please select the assigned Claims Manager!" }]}
          >
            <Select
              showSearch
              onChange={() => context.changeHandler(['claims_manager_uuid'])}
              placeholder="Select"
            >
              {claimsManagers?.map((data: any) => {
                return (
                  <Select.Option key={data.uuid} value={data.uuid}>
                    {data.full_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {context.errorMessage?.claims_manager_uuid && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.claims_manager_uuid[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('intimation_date')}
            label="Date of Intimation"
            name="intimation_date"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <DatePicker
              format="DD-MMM-YYYY"
              onChange={() => context.changeHandler(['intimation_date'])}
              placeholder="DD-MMM-YYYY"
              disabledDate={(current: any) => current.isAfter(moment())}
            />
          </Form.Item>
          {context.errorMessage?.intimation_date && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.intimation_date[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('date_of_document_received')}
            label={<span>Date of Document Received <Tooltip message={"The date on which the claims manager received the documents from the insured"} /></span>}
            name="date_of_document_received"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <DatePicker
              format="DD-MMM-YYYY"
              onChange={() => context.changeHandler(['date_of_document_received'])}
              placeholder="DD-MMM-YYYY"
              disabledDate={(current: any) => current.isAfter(moment())}
            />
          </Form.Item>
          {context.errorMessage?.date_of_document_received && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.date_of_document_received[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('date_of_document_submission')}
            label={<span>Date of Document Submission <Tooltip message={"The date on which the documents were submitted to the insurer by the claims manager"} /></span>}
            name="date_of_document_submission"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <DatePicker
              format="DD-MMM-YYYY"
              onChange={() => context.changeHandler(['date_of_document_submission'])}
              placeholder="DD-MMM-YYYY"
              disabledDate={(current: any) => current.isAfter(moment())}
            />
          </Form.Item>
          {context.errorMessage?.date_of_document_submission && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.date_of_document_submission[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('date_of_query_raised')}
            label="Date of Query raised"
            name="date_of_query_raised"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <DatePicker
              format="DD-MMM-YYYY"
              onChange={() => context.changeHandler(['date_of_query_raised'])}
              placeholder="DD-MMM-YYYY"
              disabledDate={(current: any) => current.isAfter(moment())}
            />
          </Form.Item>
          {context.errorMessage?.date_of_query_raised && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.date_of_query_raised[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('date_of_query_doc_submission')}
            label="Date of Query doc submission"
            name="date_of_query_doc_submission"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <DatePicker
              format="DD-MMM-YYYY"
              onChange={() => context.changeHandler(['date_of_query_doc_submission'])}
              placeholder="DD-MMM-YYYY"
              disabledDate={(current: any) => current.isAfter(moment())}
            />
          </Form.Item>
          {context.errorMessage?.date_of_query_doc_submission && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.date_of_query_doc_submission[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('date_of_final_status')}
            label="Date of Approval/Rejection/Withdrawal"
            name="date_of_final_status"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <DatePicker
              format="DD-MMM-YYYY"
              onChange={() => context.changeHandler(['date_of_final_status'])}
              placeholder="DD-MMM-YYYY"
              disabledDate={(current: any) => current.isAfter(moment())}
            />
          </Form.Item>
          {context.errorMessage?.date_of_final_status && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.date_of_final_status[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('date_of_settlement')}
            label="Date of Settlement"
            name="date_of_settlement"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <DatePicker
              format="DD-MMM-YYYY"
              onChange={() => context.changeHandler(['date_of_settlement'])}
              placeholder="DD-MMM-YYYY"
              disabledDate={(current: any) => current.isAfter(moment())}
            />
          </Form.Item>
          {context.errorMessage?.date_of_settlement && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.date_of_settlement[0]}
            </div>
          )}
        </Col>
        <Col span={8}>
          <div className="title mt-24">C. Remarks</div>
          <Form.Item
            className={context?.isUnmappedField('remarks')}
            label="Internal Remarks"
            name="remarks"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <Input.TextArea onChange={() => context.changeHandler(['remarks'])} placeholder="eg. St. Xaviers Medical Center" />
          </Form.Item>
          {context.errorMessage?.remarks && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.remarks[0]}
            </div>
          )}
        </Col>
      </Row>
    </Form>
    <Row className="mt-24" justify="end" gutter={16}>
      <Col>
        <Button type="secondary" onClick={() => navigate('..')}>Back</Button>
      </Col>
      <Col>
        <Button type="primary" onClick={() => updateDetails(false)}>Update</Button>
      </Col>
      <Col>
        <Button type="primary" onClick={() => updateDetails(true)}>Update and go to Treatment Details</Button>
      </Col>
    </Row>
  </Loader>
}

export default MemberClaimDetails;
