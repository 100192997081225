import { Table } from 'antd';
import { useEffect, useState } from 'react';
import PaginationComponent from './PaginationComponent';
import './_tablecomponent.scss';

interface T {
  limit: number;
  offset: number;
}

interface Props {
  dataSource: any;
  columns: any;
  count?: number;
  getCheckList?: any;
  params?: any;
  rowClick?: any;
  tableLength?: number;
  setRowKey?: any;
  loading?: boolean;
  urlOffset?: number;
  urlLimit?: number;
  hasPaging?: boolean;
  currentPageHandler?: (data: T) => void;
  expandable?: any;
  type?: 'default' | 'custom';
  expandableKey?: any;
  rowClassName?: any;
}

const TableComponent: React.FC<Props> = ({
  dataSource,
  columns,
  count,
  getCheckList,
  rowClick,
  setRowKey,
  loading,
  urlOffset,
  urlLimit,
  currentPageHandler,
  hasPaging = true,
  expandable,
  type,
  expandableKey,
  rowClassName,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelectNone: () => {
      setSelectedRowKeys([]);
    },
  };

  useEffect(() => {
    rowSelection.onSelectNone();
  }, [dataSource]);

  useEffect(() => {
    if (getCheckList) {
      getCheckList(selectedRowKeys);
    }
  }, [selectedRowKeys]);

  const rowKey = (record: any) => {
    switch (setRowKey) {
      case 'id':
        return record.id;

      case 'uuid':
        return record.uuid;

      case 'index':
        return dataSource.indexOf(record);

      default:
        return dataSource.indexOf(record);
    }
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        className="table"
        {...(getCheckList && { rowSelection: { ...rowSelection } })}
        {...(rowKey && { rowKey: (record) => rowKey(record) })}
        {...(rowClick && { onRow: (record: any, rowIndex: any) => ({ onClick: () => rowClick(rowIndex, record) }) })}
        {...(loading && { loading: true })}
        {...(type !== 'default' && { pagination: false })}
        {...(expandable && {
          expandable: {
            expandedRowRender: (record) => expandable(record),
            rowExpandable: (record) => record?.[expandableKey]?.length,
            expandIcon: ({ expanded, onExpand, record }) => {
              if (record?.[expandableKey]?.length) {
                if (expanded) {
                  return (
                    <div className="cta" onClick={(e) => onExpand(record, e)}>
                      Hide
                    </div>
                  );
                } else {
                  return (
                    <div className="cta" onClick={(e) => onExpand(record, e)}>
                      View
                    </div>
                  );
                }
              }
            },
          },
        })}
        {...(rowClassName && { rowClassName: rowClassName })}
      />
      {(!type || type !== 'default') && hasPaging && (
        <PaginationComponent
          total={count}
          onPageChange={currentPageHandler}
          urlOffset={urlOffset}
          urlLimit={urlLimit}
          // tab={params?.tab}
        />
      )}
    </div>
  );
};

export default TableComponent;
