import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import ToolTip from '../Tooltip/Tooltip';
import './_button.scss';

interface Props {
  children: any;
  onClick?: any;
  disabled?: boolean;
  active?: boolean;
  style?: any;
  loading?: boolean;
  block?: boolean;
  className?: string;
  type?: 'primary' | 'secondary' | 'tab';
  size?: 'large' | 'medium' | 'small';
  editPermission?: boolean;
  tooltip?: string;
  dashed?: boolean;
}

export const Button: React.FC<Props> = (props) => {
  const userEditPermission = props?.editPermission === false ? props?.editPermission : true;
  return (
    <Tooltip title={props?.tooltip} placement="top">
      <span>
        <button
          className={`button ${props?.type} ${props?.loading ? 'disabled' : ''} ${props?.block ? 'block' : ''} ${props?.size ? `${props?.size}` : 'large'
            } ${props?.dashed ? ' dashed' : ''}`}
          disabled={props?.disabled || props?.loading || !userEditPermission}
          onClick={props?.onClick}
          style={props?.style}
        >
          {props?.children}&nbsp;
          {props?.loading && <LoadingOutlined className="btn-loader" />}
        </button>
      </span>
    </Tooltip >
  );
};

export const TabButton: React.FC<Props> = (props) => {
  return (
    <button className={`button primary-text tab ${props.active ? 'active' : null} `} onClick={props.onClick} style={props.style} >
      {props.children} {props?.tooltip && <ToolTip message={props.tooltip} />}
    </button>
  );
};
