import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../../api/api';
import Loader from '../../../components/Loader/Loader';
import TableComponent from '../../../components/UI/Table/TableComponent';
import { errorHandler, toTitleCase } from '../../../utils/helper';



const Notifications: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false),
    [dataSource, setDataSource] = useState<Array<any> | null>(),
        updateNotification = (id: number, enabled: boolean) => {
            api.settings.updateNotificationSettings(id, { enabled: enabled })
                .then((response) => {
                })
                .catch((error) => {
                    errorHandler(error?.response)
                });
        },
        getTableData = () => {
            api.settings.getNotificationSettings()
                .then((response) => {
                    setDataSource(response?.data);
                })
                .catch((error) => {
                    errorHandler(error?.response)
                });
        },
        toggle = (channel: any) => {
            return (
                <Switch
                    defaultChecked={channel?.enabled}
                    onChange={(val) => {
                        updateNotification(channel?.id, val);
                    }}
                />
            )
        },
        columns = [
            {
                title: 'Notification Type',
                dataIndex: 'notification_type',
                render: (value: any) => toTitleCase(value),
            },
            {
                title: `App`,
                dataIndex: 'app',
                render: (value: any, record: any) => toggle(record?.channel_list?.find((item: any) => item?.notification_channel.toLowerCase() === 'app')),
            },
            {
                title: `Email`,
                dataIndex: 'email',
                render: (value: any, record: any) => toggle(record?.channel_list?.find((item: any) => item?.notification_channel.toLowerCase() === 'email')),
            },
        ],
        permissions = useSelector((state: any) => state.auth.permissions);

    useEffect(() => {
        setLoading(true);
        getTableData();
    }, []);

    useEffect(() => {
        if (dataSource) {
            setLoading(false);
        }
    },[dataSource])

    return (
        <Loader
            loading={Object?.keys(permissions)?.length === 0}
        >
            <div className="mt-32">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={loading}
                    hasPaging={false}
                />
            </div>
        </Loader>
    );
};

export default Notifications;
